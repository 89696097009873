<template>
  <div class="hx-steps">
    <div v-for="(step, index) in steps" :key="step.id">
      <div class="hx-wrapper-step">
        <div class="hx-step" :class="{ hxcurrency: index === active }">
          <!-- step number -->
          <strong class="hx-step-number" v-show="index >= active">
            {{ index + 1 }}
          </strong>
          <!-- step check -->
          <div v-show="!(index >= active)" class="hx-check-button-step">
            <svg
              height="800px"
              width="800px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 17.837 17.837"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                />
              </g>
            </svg>
          </div>
        </div>
        <span class="hx-step-text">{{ step.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HXSteps",
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => [
        {
          id: 1,
          text: "Intenta",
        },
        {
          id: 2,
          text: "Falla",
        },
        {
          id: 3,
          text: "Aprende",
        },
        {
          id: 4,
          text: "Repite",
        },
      ],
    },
    active: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.hx-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.hx-wrapper-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.hx-step-number {
  font-size: 2vw;
  opacity: 0.8;
  .hxcurrency & {
    opacity: 1;
  }
}

.hx-step-text {
  font-size: 0.8rem;
}

.hx-step {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--gray-400);
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  font-size: 2rem;
}

.hx-step.hxcurrency {
  color: var(--primary);
  border: solid 2px var(--primary);
}

.hx-check-button-step {
  height: 100%;
  width: 100%;
  background-color: var(--lego-bg);
  border-radius: 50%;
  transition: all 0.3s;
  position: relative;
}

.hx-check-button-step svg {
  width: 100%;
  height: 100%;
  transform: scale(0.65);
  // fill: var(--lego-hsuccess);
  fill: #1e2023;
  .dark & {
    fill: aliceblue;
  }
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .hx-step {
    width: 8vw;
    height: 8vw;
  }
  .hx-step-number {
    font-size: 3vw;
  }
}
</style>
