<script>
import BVForm from "@/components/BVForm/BVForm";
import HXSteps from "@/components/helexium/HXSteps.vue";
import moment from "moment";

const schema = [
  {
    name: "NumeroDeDocumento",
    type: "text",
    label: "Numero de documento",
    value: null,
    validations: "required",
    state: "",
  },
];

export default {
  name: "navegacionAspirante",
  components: {
    BVForm,
    HXSteps,
  },
  data() {
    return {
      formSearch: schema,
      postulaciones: [],
      stepSearch: 1,
      processSteps: [
        {
          id: 1,
          text: "Pre-selección",
        },
        {
          id: 2,
          text: "Selección",
        },
        {
          id: 3,
          text: "Vinculación",
        },
      ],
    };
  },
  methods: {
    async getVacante(id) {
      const response = await this.$store.getters.fetchGet({
        path: `vacante/${id}`,
      });
      const vacante = await response.json();
      console.log(vacante);
      return vacante;
    },
    async onSearch(self) {
      const { NumeroDeDocumento } = self.data;
      console.log(NumeroDeDocumento);

      self.loader = true;
      this.$store.getters
        .fetchGet({ path: `Postulacion/postulaciones/${NumeroDeDocumento}` })
        .then(async (response) => {
          if (response.ok) {
            const result = await response.json();

            // Asigna las postulaciones y carga sus vacantes
            this.postulaciones = await Promise.all(
              result.map(async (postulacion) => {
                const vacante = await this.getVacante(postulacion.vacante.id);
                return {
                  ...postulacion,
                  vacante, // Asocia la vacante obtenida a la postulación
                };
              })
            );

            console.log(this.postulaciones);

            this.formSearch[0].state = "success";
            this.stepSearch = 2;
          } else {
            throw new Error("Error al obtener los datos del postulante");
          }
        })
        .catch(() => {
          this.formSearch[0].state = "error";
          this.formSearch[0].errorMessages = ["No se encontró el postulante"];
        })
        .finally(() => {
          self.loader = false;
        });
    },
    watcher() {
      this.formSearch[0].state = "";
      this.formSearch[0].errorMessages = [];
    },
  },
  computed: {
    activeStepProcess() {
      if (!this.postulaciones || !this.postulaciones.length) return [];

      // Mapeo de cada postulacion para contar etapas aprobadas junto con su id
      return this.postulaciones.map((postulacion) => {
        let approvedSteps = 0;

        const preseleccionEstado = postulacion.preseleccionEvaluacion.estado;
        const seleccionEstado = postulacion.seleccionEvaluacion.estado;
        const vinculacionEstado = postulacion.vinculacionEvaluacion.estado;

        if (preseleccionEstado === "Aprobado") {
          approvedSteps++;
        }
        if (seleccionEstado === "Aprobado") {
          approvedSteps++;
        }
        if (vinculacionEstado === "Aprobado") {
          approvedSteps++;
        }

        return {
          id: postulacion.id,
          approvedSteps,
          states: {
            preseleccion: preseleccionEstado,
            seleccion: seleccionEstado,
            vinculacion: vinculacionEstado,
          },
        };
      });
    },
    maincontent() {
      if (this.stepSearch != 1) {
        return {
          opacity: 1,
        };
      } else {
        return {};
      }
    },
    activeUser() {
      const user = localStorage.getItem("setPersonaAct");
      console.log("user", user);
      if (user) {
        return JSON.parse(user);
      }
      return null;
    },
  },
  filters: {
    formattedDate(value) {
      if (value == "0001-01-01T00:00:00") return "No hay información";
      const regex1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+$/;
      const regex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

      if (regex1.test(value) || regex2.test(value)) {
        console.log(value);
        const fecha = new Date(value);
        return moment(fecha).format("DD/MM/YYYY hh:mm A");
      } else {
        return value;
      }
    },
  },
};
</script>
<template>
  <HXContentCard
    title="Seguimiento de postulación"
    :toBack="activeUser && { name: 'AdministradorDeVacantes' }"
  >
    <div class="globalContainer">
      <div class="row p-4">
        <BVForm
          class="col-12 col-lg-6 mx-lg-auto"
          :schema="formSearch"
          :observer="['NumeroDeDocumento']"
          @watcher="watcher"
          title="Búsqueda de etapa en el proceso de contratación"
          :callBackSubmit="onSearch"
        >
          <template #text-submit> Buscar </template>
          <template #icon-submit> <i class="fas fa-search"></i> </template>
        </BVForm>
      </div>

      <dir v-if="stepSearch != 1 && !postulaciones.length">
        <p class="text-center h4">
          No se encontraron procesos para el postulante.
        </p>
      </dir>

      <section
        class="main-content"
        :style="maincontent"
        v-for="postulacion in postulaciones"
        :key="postulacion.id"
      >
        <hr />
        <!-- steps -->
        <HXSteps
          :steps="processSteps"
          :active="
            activeStepProcess.find((step) => step.id === postulacion.id)
              ?.approvedSteps || 0
          "
        />
        <b-list-group class="row">
          <b-list-group-item
            class="col-12 col-lg-6 mx-lg-auto d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-3"
            style="gap: 1rem"
          >
            <div class="d-flex align-items-center">
              <img
                :src="postulacion.vacante.cargo.sucursal.empresa.urlLogo"
                :alt="postulacion.vacante.cargo.sucursal.empresa.nombre"
                width="90"
                class="mr-3"
              />
              <div>
                <h3>
                  <strong>
                    {{ postulacion.vacante.cargo.sucursal.empresa.nombre }}
                  </strong>
                </h3>
                <p class="mt-1 mb-0">
                  <strong>
                    {{ postulacion.vacante.cargo.nombre }}
                  </strong>
                </p>
              </div>
            </div>

            <router-link
              v-if="postulacion.preseleccionEvaluacion.estado == 'Pendiente'"
              class="btn btn-primary btn-sm"
              :to="{
                name: 'FormularioSubDePostulacion',
                params: {
                  vacanteId: postulacion.vacante.id,
                  aspiranteId: postulacion.id,
                },
              }"
            >
              <i class="fas fa-pen"></i>
              Editar postulacion
            </router-link>
            <router-link
              v-if="
                postulacion.preseleccionEvaluacion.estado == 'Aprobado' &&
                postulacion.seleccionEvaluacion.estado == 'Pendiente'
              "
              class="btn btn-danger btn-sm"
              :to="{
                name: 'CargarDocumentosDeSeleccion',
                params: {
                  vacanteId: postulacion.vacante.id,
                  postulacionId: postulacion.id,
                },
              }"
            >
              <i class="fas fa-upload"></i>
              Cargar Documentos
            </router-link>
          </b-list-group-item>
          <!-- info vacante -->
          <b-list-group-item
            class="col-12 col-lg-6 mx-lg-auto content-items-info"
          >
            <div class="item-info">
              <h6>Tipo de contrato</h6>
              <span> {{ postulacion.vacante.tipoDeContrato.tipo }}</span>
            </div>
            <div class="item-info">
              <h6>Jornada laboral</h6>
              <span> {{ postulacion.vacante.jornadaLaboral.name }}</span>
            </div>
            <div class="item-info">
              <h6>Salario</h6>
              <span> $ {{ postulacion.vacante.salario }}</span>
            </div>
            <div class="item-info">
              <h6>Número de vacantes</h6>
              <span> {{ postulacion.vacante.cantidadDeVacantesInicial }}</span>
            </div>
            <div class="item-info">
              <h6>Fecha de publicacion</h6>
              <span>
                {{ postulacion.vacante.fechaDeCreacion | formattedDate }}</span
              >
            </div>
            <div class="item-info">
              <h6>Fecha de cierre</h6>
              <span>
                {{ postulacion.vacante.fechaDeCierre | formattedDate }}</span
              >
            </div>
            <div class="item-info">
              <h6>País</h6>
              <span> {{ postulacion.vacante.pais.descripcion }}</span>
            </div>
            <div class="item-info">
              <h6>Municipio</h6>
              <span> {{ postulacion.vacante.municipio.descripcion }}</span>
            </div>
            <div class="item-info">
              <h6>Dirección</h6>
              <span> {{ postulacion.vacante.direccion }}</span>
            </div>
            <div class="item-info">
              <h6>Descripción</h6>
              <span> {{ postulacion.vacante.descripcion }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </section>
    </div>
  </HXContentCard>
</template>

<style scoped>
.main-content {
  padding: 1rem 2rem;
  width: 100%;
  opacity: 0;
  transition: 1s;
}

.content-items-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  gap: 1rem;
}

@media (min-width: 768px) {
  .content-items-info {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
