var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Seguimiento de postulación","toBack":_vm.activeUser && { name: 'AdministradorDeVacantes' }}},[_c('div',{staticClass:"globalContainer"},[_c('div',{staticClass:"row p-4"},[_c('BVForm',{staticClass:"col-12 col-lg-6 mx-lg-auto",attrs:{"schema":_vm.formSearch,"observer":['NumeroDeDocumento'],"title":"Búsqueda de etapa en el proceso de contratación","callBackSubmit":_vm.onSearch},on:{"watcher":_vm.watcher},scopedSlots:_vm._u([{key:"text-submit",fn:function(){return [_vm._v(" Buscar ")]},proxy:true},{key:"icon-submit",fn:function(){return [_c('i',{staticClass:"fas fa-search"})]},proxy:true}])})],1),(_vm.stepSearch != 1 && !_vm.postulaciones.length)?_c('dir',[_c('p',{staticClass:"text-center h4"},[_vm._v(" No se encontraron procesos para el postulante. ")])]):_vm._e(),_vm._l((_vm.postulaciones),function(postulacion){return _c('section',{key:postulacion.id,staticClass:"main-content",style:(_vm.maincontent)},[_c('hr'),_c('HXSteps',{attrs:{"steps":_vm.processSteps,"active":_vm.activeStepProcess.find((step) => step.id === postulacion.id)
            ?.approvedSteps || 0}}),_c('b-list-group',{staticClass:"row"},[_c('b-list-group-item',{staticClass:"col-12 col-lg-6 mx-lg-auto d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mt-3",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-3",attrs:{"src":postulacion.vacante.cargo.sucursal.empresa.urlLogo,"alt":postulacion.vacante.cargo.sucursal.empresa.nombre,"width":"90"}}),_c('div',[_c('h3',[_c('strong',[_vm._v(" "+_vm._s(postulacion.vacante.cargo.sucursal.empresa.nombre)+" ")])]),_c('p',{staticClass:"mt-1 mb-0"},[_c('strong',[_vm._v(" "+_vm._s(postulacion.vacante.cargo.nombre)+" ")])])])]),(postulacion.preseleccionEvaluacion.estado == 'Pendiente')?_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
              name: 'FormularioSubDePostulacion',
              params: {
                vacanteId: postulacion.vacante.id,
                aspiranteId: postulacion.id,
              },
            }}},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" Editar postulacion ")]):_vm._e(),(
              postulacion.preseleccionEvaluacion.estado == 'Aprobado' &&
              postulacion.seleccionEvaluacion.estado == 'Pendiente'
            )?_c('router-link',{staticClass:"btn btn-danger btn-sm",attrs:{"to":{
              name: 'CargarDocumentosDeSeleccion',
              params: {
                vacanteId: postulacion.vacante.id,
                postulacionId: postulacion.id,
              },
            }}},[_c('i',{staticClass:"fas fa-upload"}),_vm._v(" Cargar Documentos ")]):_vm._e()],1),_c('b-list-group-item',{staticClass:"col-12 col-lg-6 mx-lg-auto content-items-info"},[_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Tipo de contrato")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.tipoDeContrato.tipo))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Jornada laboral")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.jornadaLaboral.name))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Salario")]),_c('span',[_vm._v(" $ "+_vm._s(postulacion.vacante.salario))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Número de vacantes")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.cantidadDeVacantesInicial))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Fecha de publicacion")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formattedDate")(postulacion.vacante.fechaDeCreacion)))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Fecha de cierre")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formattedDate")(postulacion.vacante.fechaDeCierre)))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("País")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.pais.descripcion))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Municipio")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.municipio.descripcion))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Dirección")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.direccion))])]),_c('div',{staticClass:"item-info"},[_c('h6',[_vm._v("Descripción")]),_c('span',[_vm._v(" "+_vm._s(postulacion.vacante.descripcion))])])])],1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }